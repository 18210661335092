import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const CanalCarpien = () => (
  <Layout
    title="Canal carpien : Diagnostic et traitement"
    description="Le Syndrôme du canal carpien est trés répandu. Si un traitement médical peut être envisagé pour une forme débutante l'opération est souvent nécessaire."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Le syndrome du canal carpien" />
        <Typography>
          <Typography variant="span" weight="bold">
            Le syndrome du canal carpien
          </Typography>
          {' '}
          (parfois appelé syndrome du tunnel carpien) est une
          pathologie courante qui touche environ 130 000 personnes par an. La maladie
          se déclare le plus souvent après 40 ans et est prédominante chez la femme.
          Elle est aisément traitable, surtout dans les formes peu avancées.
        </Typography>
        <MainPart title="Le syndrome du canal carpien à la loupe">
          <Typography>
            Pour bien comprendre le syndrome, il faut tout d’abord connaître l’anatomie
            du canal carpien.
          </Typography>
          <Typography>
            Le carpe est un ensemble de 8 petits os localisé au niveau du poignet qui
            forme le plancher du canal carpien. Le toit du canal carpien est constitué
            par un ligament très épais, nommé ligament rétinaculaire.
          </Typography>
          <Typography>
            Le canal carpien est traversé par les tendons fléchisseurs entourés de
            leur membrane synoviale et par le nerf médian.
          </Typography>
          <Typography>
            Le nerf médian est un nerf mixte (figure 1) qui assure la sensibilité du
            pouce, de l’index, du majeur et de la moitié de l’annulaire. Il assure
            également la motricité de la main (muscles de la loge thénar).
          </Typography>
          <Typography>
            Le syndrome du canal carpien est la traduction clinique de la compression
            du nerf médian à son entrée dans la main, au niveau du canal carpien.
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-canalcarpien.jpg"
            caption="Figure 1. Territoire du nerf médian concerné par la compression de nerf au canal carpien"
            currentOpTitle="Canal carpien"
          />
        </MainPart>
        <MainPart title="Les causes possibles du canal carpien">
          <div className="flex flex-col">
            <Typography>
              La compression du nerf médian dans le canal carpien est la conséquence d’une
              inadéquation entre le contenant et le contenu dans un canal inextensible.
            </Typography>
            <Typography>
              Les étiologies (causes) les plus fréquentes sont :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography weight="bold">
                Idiopathique
                {' '}
                <Typography variant="span">
                  : en effet, le plus souvent, on ne connaît pas la cause.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une synovite (inflammation de la membrane synoviale) des tendons fléchisseurs
                <Typography variant="span">
                  , dans le cadre d’une maladie rhumatismale comme la polyarthrite
                  rhumatoïde, le lupus, etc.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une synovite des tendons fléchisseurs
                {' '}
                <Typography variant="span">
                  causée par une activité intense et répétitive. Cette cause peut
                  rentrer dans le cadre d’une maladie professionnelle.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une déformation post-traumatique du squelette
                {' '}
                <Typography variant="span">
                  : fracture du radius, des os du carpe…
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une tumeur intra-canalaire
                {' '}
                <Typography variant="span">
                  : lipome, kyste synovial…
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une cause endocrinienne
                {' '}
                <Typography variant="span">
                  : grossesse, péri-ménopause, hypothyroïdie, diabète…
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Les symptômes du syndrome du canal carpien">
          <div className="flex flex-col">
            <Typography>
              Le diagnostic du syndrome du canal carpien est clinique. Le patient
              qui consulte se plaint de fourmillements, de brûlures, voire d’électricité
              dans le territoire du nerf médian. Ces symptômes sont le plus souvent
              présents la nuit et peuvent être responsables de réveils nocturnes.
            </Typography>
            <Typography>
              Dans les formes évoluées du syndrome du canal carpien, on peut observer une
              perte de la sensibilité des doigts, une maladresse de la main avec perte de
              force voire une fonte musculaire au niveau de la base du pouce (loge thénar).
            </Typography>
          </div>
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer le syndrome du canal carpien ?">
          <div className="flex flex-col">
            <Typography>
              Pour valider le diagnostic du syndrome du canal carpien, l’électromyogramme
              (ou EMG) est un examen indispensable.
            </Typography>
            <Typography>En effet, il permet de :</Typography>
          </div>
          <List>
            <List.Item>
              <Typography>Confirmer le siège de la compression nerveuse</Typography>
            </List.Item>
            <List.Item>
              <Typography>De préciser l&apos;importance de la compression nerveuse</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                De rechercher une compression nerveuse associée, comme le syndrome du nerf
                ulnaire (cubital) au coude.
              </Typography>
            </List.Item>
          </List>
          <Typography>
            L’examen est réalisé par un neurologue en cabinet. Celui-ci va mesurer le courant
            électrique véhiculé par le nerf à l’aide de petites aiguilles.
          </Typography>
        </MainPart>
        <MainPart title="Les traitements médicaux et chirurgicaux pour le syndrome du canal carpien">
          <Typography>
            Pour traiter le syndrome du canal carpien, deux solutions sont envisageables,
            en fonction du stade de la pathologie.
          </Typography>
          <Typography size="mdx" weight="bold">
            Le traitement médical :
          </Typography>
          <Typography>
            Le traitement médical est indiqué chez les patients qui présentent une forme
            débutante de la pathologie : port d’une attelle de repos nocturne associée à une
            infiltration écho-guidée de Cortisone au niveau du canal carpien.
          </Typography>
          <Typography size="mdx" weight="bold">
            Le traitement chirurgical :
          </Typography>
          <Typography>
            Le traitement chirurgical est indiqué en cas d’échec du traitement médical, ou
            bien d’emblée, dans les formes sévères avec perte de sensibilité et/ou de motricité.
          </Typography>
          <div className="flex flex-col gap-y-2">
            <Typography>
              L’intervention chirurgicale dure une dizaine de minutes et est le plus souvent
              réalisée sous anesthésie loco-régionale (seul le bras sera endormi) en
              ambulatoire (le patient n’est pas hospitalisé).
            </Typography>
            <Typography>
              Le but de l’intervention est d’élargir le canal carpien en sectionnant le ligament
              rétinaculaire qui forme le toit du tunnel carpien.
            </Typography>
            <Typography>
              L’opération est effectuée sous endoscopie. Une petite incision (1,5 cm) est réalisée
              au niveau du pli de flexion du poignet (Figure 2). Le chirurgien peut alors
              introduire dans la main un instrument spécifique comprenant une caméra et une
              lame rétractable (Figure 3). Après avoir écarté le nerf et les tendons
              fléchisseurs, le ligament est visualisé, puis sectionné à l’aide de la lame.
            </Typography>
            <Typography>
              La méthode endoscopique permet une récupération post-opératoire plus rapide par
              rapport à la technique conventionnelle à ciel ouvert. En outre, elle présente un
              avantage esthétique.
            </Typography>
          </div>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure2-canalcarpien.jpg"
              caption="Figure 2. Incision cutanée pour la chirurgie du canal carpien"
              currentOpTitle="Canal carpien"
            />
            <Figure
              imageName="figure3-canalcarpien.jpg"
              caption="Figure 3. Instrument de libération du canal carpien muni d&apos;un optique et d&apos;une lame rétractable"
              currentOpTitle="Canal carpien"
            />
          </div>
          <Typography>
            La chirurgie conventionnelle (Figure 4) à ciel ouvert est réalisée en cas :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                de mauvaise visibilité pendant l’opération (synovite importante)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>d’anomalie anatomique</Typography>
            </List.Item>
            <List.Item>
              <Typography>de reprise chirurgicale</Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure4-canalcarpien.jpg"
            caption="Figure 4. Récidive de canal carpien ayant nécessité une reprise chirurgicale à ciel ouvert"
            currentOpTitle="Canal carpien"
          />
        </MainPart>
        <MainPart title="Les suites post-opératoires de l’opération chirurgicale du syndrome du canal carpien">
          <Typography>
            Après une chirurgie du syndrome du canal carpien, les engourdissements
            disparaissent en général le jour de l’intervention. Aucune immobilisation
            n’est nécessaire et la main peut être utilisée dès le lendemain, sans forcer.
          </Typography>
          <Typography>
            Un premier pansement est réalisé une semaine après l’opération, tous les 2 jours
            pendant une semaine. Les fils sont quant à eux résorbables. La rééducation
            post-opératoire n’est pas forcément nécessaire. Le patient peut également
            reconduire à partir du 7ème jour.
          </Typography>
          <Typography>
            Si le patient présente une perte de sensibilité et/ou de motricité avant l’opération,
            la récupération est plus longue, voire incomplète dans les formes sévères.
          </Typography>
          <Typography>
            Une douleur « en barre » au niveau du talon de la main est fréquente et peut durer
            plusieurs semaines.
          </Typography>
          <Typography>
            Après l’opération, la perte de force est habituelle et peut durer plusieurs mois.
          </Typography>
          <Typography>
            Enfin, la durée de l’arrêt de travail est variable. Il peut aller de 21 à 45 jours,
            en fonction de la profession du patient.
          </Typography>
        </MainPart>
        <MainPart title="Les complications possibles après une opération pour le syndrome du canal carpien">
          <Typography>
            Comme toutes les opérations, l’intervention chirurgicale du syndrome du canal carpien
            peut présenter des complications secondaires. Elles sont classées en deux catégories.
          </Typography>
          <Typography size="mdx" weight="bold">Les complications non spécifiques</Typography>
          <Typography>
            Les complications non spécifiques ne sont pas dépendantes du type de chirurgie :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Une infection du site opératoire pouvant nécessiter une intervention de nettoyage
                associée à la prise d’antibiotique.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un hématome, qui peut demander une évacuation en cas de menace cutanée ou de
                compression nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une algodystrophie. L’apparition de ce trouble est indépendante du type de
                chirurgie. L’algodystrophie évolue en deux phases. La phase chaude, avec
                une main gonflée, douloureuse avec transpiration. Puis la phase froide,
                avec une prédominance de la raideur. L’évolution est le plus souvent longue
                (12 à 18 mois) et des séquelles sont possibles, comme une douleur et/ou
                raideur des articulations de la main voire de l’ensemble du membre.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les accidents liés à l’anesthésie.
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Les complications spécifiques</Typography>
          <Typography>
            Les complications spécifiques sont quant à elles liées à la chirurgie
            du syndrome du canal carpien :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                La libération incomplète du ligament réticulaire.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une plaie nerveuse nécessitant une reprise chirurgicale précoce,
                afin de réaliser une suture nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un doigt à ressaut (douleurs et blocages du doigt lors de la flexion).
                Il s’agit le plus souvent d’une pathologie préexistante qui est majorée
                par l’intervention. Une infiltration, voire une libération chirurgicale
                de la poulie A1 peuvent être envisagées.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une récidive. Elle est exceptionnelle et systématiquement recherchée en
                cas de réapparition des fourmillements. Un nouvel EMG permet de confirmer
                le diagnostic.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default CanalCarpien;
